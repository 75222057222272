<template>
  <div class="mobile-home">
    <div class="mobile-home-header">
      <img src="../../../assets/mobile_images/mo_medicine_bg.png" alt="" />
      <!-- <div class="mobile-header-btn">进一步了解</div> -->
    </div>
    <mobileTitle titleZh="供应链平台" marginTopZh=".46rem" marginBottomZh=".46rem"></mobileTitle>
    <mobileBox :boxList="boxList1"></mobileBox>
    <mobileTitle titleZh="数据产品" marginTopZh=".46rem" marginBottomZh=".46rem"></mobileTitle>
    <mobileBox :boxList="boxList2"></mobileBox>
  </div>
</template>
<script>
import mobileBox from '@/components/box/mobile_box.vue'; //盒子组件
import mobileTitle from '@/components/title/mobile_title.vue'; //标题组件
export default {
  components: {
    mobileTitle,
    mobileBox
  },
  data() {
    return {
      boxList1:[
        { 
          title:'药链云-<br>SAAS药企供应链平台',
          src:require('../../../assets/mobile_images/mo_medicine_gylpt1.png'),
          path:'/medicine/platform'
        },
        { 
          title:'四方云-<br>供应链&营销数字化平台',
          src:require('../../../assets/mobile_images/mo_medicine_gylpt2.png'),
          path:'/medicine/platform'
        },
        { 
          title:'WMS-<br>仓储管理系统',
          src:require('../../../assets/mobile_images/mo_medicine_gylpt3.png'),
          path:'/medicine/platform'
        },
        { 
          title:'传世链条-<br>开放式数据接口平台',
          src:require('../../../assets/mobile_images/mo_medicine_gylpt4.png'),
          path:'/medicine/platform'
        }
      ],
      boxList2:[
        { 
          title:'商业智能BI-SMAS',
          src:require('../../../assets/mobile_images/mo_medicine_sjcp1.png'),
          path:'/medicine/data'
        },
        { 
          title:'智能算法',
          src:require('../../../assets/mobile_images/mo_medicine_sjcp2.png'),
          path:'/medicine/data'
        },
        { 
          title:'大数据报告',
          src:require('../../../assets/mobile_images/mo_medicine_sjcp3.png'),
          path:'/medicine/data',
          type:1
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
.mobile-home {
  .mobile-home-header {
    position: relative;
    min-height: 5rem;
    img {
      width: 100%;
    }
    .mobile-header-btn {
      position: absolute;
      left: 50%;
      bottom: 1.8rem;
      transform: translateX(-50%);
      width: 2.4rem;
      height: 0.72rem;
      line-height: 0.72rem;
      font-size: 0.28rem;
      text-align: center;
      color: #fff;
      background: #4fa1a4;
      border-radius: 0.04rem;
    }
  }
}
</style>
