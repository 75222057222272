<template>
  <div class="home-block">
    <div class="home">
      <!-- 头部开始 -->
      <div class="home-header">
        <img class="w100f" src="../../assets/images/medicine_bg.jpg" alt="" />
        <div class="bg-text">
          <div class="bg-text-left">
            <p class="font-title">药企</p>
            <div class="next-btn" @click="toPath('/medicine/logistics')">进一步了解</div>
          </div>
          <div class="bg-text-right"></div>
        </div>
      </div>
      <!-- 头部结束 -->
      <!-- 中间内容开始 -->
      <div class="main-contain">
        <Title titleZh="供应链平台"></Title>
        <Box :boxList="boxList1"></Box>
        <Title titleZh="数据产品"></Title>
        <Box :boxList="boxList2"></Box>
        <div class="h150"></div>
      </div>
    </div>
    <mobilePage></mobilePage>
  </div>
</template>

<script>
import mobilePage from '@/views/medicine/mobile/index.vue'//移动端页面
import Box from '@/components/box/box.vue'; //盒子组件
import Title from '@/components/title/title.vue'; //标题组件
export default {
  name: 'home',
  components: {
    Box,
    Title,
    mobilePage
  },
  data() {
    return {
      boxList1:[
        { 
          title:'药链云-SAAS药企供应链平台',
          src:require('../../assets/images/yao_block1_img1.png'),
          path:'/medicine/platform'
        },
        { 
          title:'四方云-供应链&营销数字化平台',
          src:require('../../assets/images/yao_block1_img2.png'),
          path:'/medicine/platform'
        },
        { 
          title:'WMS-仓储管理系统',
          src:require('../../assets/images/yao_block1_img3.png'),
          path:'/medicine/platform'
        },
        { 
          title:'传世链条-开放式数据接口平台',
          src:require('../../assets/images/yao_block1_img4.png'),
          path:'/medicine/platform'
        }
      ],
      boxList2:[
        { 
          title:'商业智能BI-SMAS',
          src:require('../../assets/images/yao_block2_img1.png'),
          path:'/medicine/data'
        },
        { 
          title:'智能算法',
          src:require('../../assets/images/yao_block2_img2.png'),
          path:'/medicine/data'
        },
        { 
          title:'大数据报告',
          src:require('../../assets/images/yao_block2_img3.png'),
          path:'/medicine/data',
          type:1
        },
      ],
      currentIndex: 0,
      dialogVisible: false,
    };
  },
  methods: {
    toPath(val){
      if(val == 'fbt'){
        window.open('http://csbrkjcom.huhuhu.net/index.html')
      }else{
        setTimeout(()=>{
          this.$router.push(val);
        },500)
      }
    },
    onTabChange(index) {
      this.currentIndex = index;
      console.log(index);
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  //头部
  .home-header {
    position: relative;
    width: 100%;
    min-height: 450px;
    .bg-text {
      width: 1200px;
      // height: 200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      .bg-text-left {
        text-align: left;
        .font-title {
          font-size: 45px;
          margin-bottom: 20px;
          color: #3d3d3d;
        }
        .next-btn {
          width: 140px;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          background: #4fa1a4;
          opacity: 1;
          border-radius: 24px;
          text-align: center;
          cursor: pointer;
        }
      }
      .bg-text-right {
        font-family: Source Han Sans CN;
        text-align: right;
        .font-one {
          font-size: 30px;
        }
        .font-two {
          font-size: 22px;
          line-height: 22px;
        }
      }
    }
  }
  //中间
  .main-contain {
    .platform-box {
      display: flex;
      flex-wrap: wrap;
      .platform-box-list {
        position: relative;
        width: calc(50% - 8px);
        cursor: pointer;
        margin-bottom: 16px;
        img {
          width: 100%;
        }
        &:nth-child(2n + 1) {
          margin-right: 16px;
        }
        .platform-hover-box {
          position: absolute;
          width: 100%;
          left: 0;
          bottom: 3px;
          opacity: 0;
          translate: 0.4s;
          display: flex;
          justify-content: center;
          flex-direction: column;
          height: 0;
          padding-left: 40px;
          background: rgba(#000, 0.7);
          color: #fff;
          text-align: left;
          box-sizing: border-box;
          .platform-hover-title{
            font-size: 24px;
            margin-bottom: 12px;
          }
          .platform-hover-txt{
            font-size: 16px;
          }
        }
        &:hover .platform-hover-box {
          height: 115px;
          opacity: 1;
        }
      }
      .platform-box-wrap {
        position: relative;
        width: 100%;
        cursor: pointer;
        margin-bottom: 16px;
        img {
          width: 100%;
        }
        .platform-hover-box {
          position: absolute;
          width: 100%;
          left: 0;
          bottom: 3px;
          opacity: 0;
          translate: 0.4s;
          display: flex;
          justify-content: center;
          flex-direction: column;
          height: 0;
          padding-left: 40px;
          background: rgba(#000, 0.7);
          color: #fff;
          text-align: left;
          box-sizing: border-box;
          .platform-hover-title{
            font-size: 24px;
            margin-bottom: 12px;
          }
          .platform-hover-txt{
            font-size: 16px;
          }
        }
        &:hover .platform-hover-box {
          height: 115px;
          opacity: 1;
        }
      }
    }
  }
}
</style>
